
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AdvertiserSurcoucheV2',
  data: function () {
    return {
      header: [
        { text: 'Name', value: 'name' },
        { text: 'Health', value: 'health' },
        { text: 'Alerts', value: 'alerts' },
        { text: 'Entities', value: 'entities' }
      ],
      singleSelect: true,
      items: []
    }
  }
})
